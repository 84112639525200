<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Terrassenüberdachung / Lewens / Murano Finestra <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Murano Finestra - Glasdach mit Markise
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full md:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Festverglasung
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Der größtmögliche Schutz vor Wind und Wetter und vor Zugluft lässt
            sich mit einer Festverglasung erreichen. Diese kann entweder als
            Teilverglasung einzelner Wandteile eingesetzt werden oder als
            Komplettverglasung ganzer Wände bis 500 cm Breite.<br /><br />
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Typen">
            <p class="text-lg">
              <span class="font-bold"
                >Erhältlich in verschiedene Produktvarianten
              </span>
              <br />
              <ul class="flex flex-col sm:flex-row">
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/finestra/lew-murano-finestra-rechteck-typ-uli.webp"
                      alt="lewens murano finestra rechteck design"
                      style="min-width: 10rem"
                    />
                  </div>
                  <div class="lg:pl-16">Murano Finestra Typ Rechteck</div>
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/finestra/lew-murano-finestra-schraeg-typ-uli.webp"
                      style="min-width: 10rem"
                      alt="lewens murano finestra schaeg design"
                    />
                  </div>
                  <div class="lg:pl-16">Murano Finestra Typ Schrägelement</div>
                </li>
              </ul>
            </p>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 500 cm<br />
              <span class="font-bold">Höhe:</span> <br />
              max. 230 cm <br />
              <span class="font-bold">Verglasung:</span> <br />
              für ESG 8 mm <br />
              für ESG 10 mm
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div><img src="../assets/madeingermany.webp" alt="made in germany siegel" /></div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/tuev.webp" alt="tuev geprueft" /></div>
                  <div class="w-full pl-1">
                    TÜV-geprüfte Sicherheit
                  </div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
      <contact></contact>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/finestra/murano-glissando-finestra.webp"),
          alt: "terrassenüberdachung lewens finestra"
        },
        {
          image: require("@/assets/img/lewens/finestra/murano-finestra-schraeg.webp"),
          alt:"lewens finestra schraeg ansicht"
        },
        {
          image: require("@/assets/img/lewens/finestra/modelle-web-rd-24.webp"),
          alt:"terrassenüberdachung 3D modell"
        },
        {
          image: require("@/assets/img/lewens/finestra/lew-murano-finestra-detail-rinne-uli.webp"),
          alt:"murano finestra rinne"
        },
      ],
    };
  },
};
</script>
